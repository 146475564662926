import axios from 'axios';
import { useEffect } from 'react';

import { getRouteMaker } from '../useApi';

/**
 * Hook used to setup the API bridge between the main process and the
 * renderer process. The requests needed by the main process are going
 * to be sent over the IPC channel, performed by the renderer process and
 * the response received by the renderer process is going to be sent back.
 */
export const useSetupApiBridge = (): void => {
  // user is already defined and used in useApiRouteMaker

  useEffect(() => {
    window.electron?.onCallApi(async ({ payload: { apiCall, query }, queryId }) => {
      const apiRoute = getRouteMaker(apiCall)(query?.params);

      try {
        const { data } = await axios({
          data: query?.body,
          method: apiRoute.method,
          url: apiRoute.url,
          withCredentials: true,
        });

        window.electron?.sendApiResponse({ payload: { data }, queryId, type: 'call-api:res' });
      } catch (error) {
        window.electron?.sendApiResponse({ payload: { error }, queryId, type: 'call-api:res' });
      }
    });
  }, []);
};
