import { Connector, ExtendedSources, GSuiteSources, O365Sources } from '@outmind/types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { useUserProfile } from '../authn';
import { useConnectors } from '../connectors';
import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Hook for retrieving the list of sources that shouled be indexed for the current user
 */
export const useOrganisationSources = (): UseQueryResult<UserSources> => {
  const { data: connectors = [] } = useConnectors();
  const { data: user } = useUserProfile();

  const makeRoute = useApiRouteMaker(ApiQuery.GET_ORGANISATION_SOURCES);

  const organisationSources = useQuery<UserSources>(
    [ApiQuery.GET_ORGANISATION_SOURCES, connectors],
    async () => {
      const route = makeRoute({ organisationId: user?.organisationId ?? user?.customerCode ?? '' });
      const result = await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
      const sources: ExtendedSources[] = result.data;
      return filterOrganisationSources(sources, connectors, user!.id);
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: true,
    },
  );

  return organisationSources;
};

/**
 * Filter the list of sources that the user should add removing from the list sources that are already connected
 */
const filterOrganisationSources = (
  sources: ExtendedSources[],
  connectors: Connector[],
  userId: string,
): UserSources => {
  if (!sources.length) {
    return {
      connectedSources: [],
      sourcesToBeConnected: [],
    };
  }

  const syncedSources = Array.from(
    new Set(
      connectors
        .filter((connector) => connector.ownerId === userId)
        .map((connector) => connector.source),
    ),
  ) as ExtendedSources[];

  const organisationSources: UserSources = sources.reduce(
    (_organisationSources, source) => {
      if (!syncedSources.includes(source) && !['o365', 'gsuite'].includes(source)) {
        return {
          ..._organisationSources,
          sourcesToBeConnected: [..._organisationSources.sourcesToBeConnected, source],
        };
      }
      if (!['o365', 'gsuite'].includes(source)) {
        return {
          ..._organisationSources,
          connectedSources: [..._organisationSources.connectedSources, source],
        };
      }

      if (source === 'o365') {
        const notSyncedO365Sources = O365Sources.filter(
          (_source) => !syncedSources.includes(_source),
        );
        if (notSyncedO365Sources.length === O365Sources.length) {
          return {
            ..._organisationSources,
            sourcesToBeConnected: ['o365', ..._organisationSources.sourcesToBeConnected],
          };
        }
        return {
          connectedSources: [
            ..._organisationSources.connectedSources,
            ...O365Sources.filter((_source) => syncedSources.includes(_source)),
          ],
          sourcesToBeConnected: [
            ..._organisationSources.sourcesToBeConnected,
            ...notSyncedO365Sources,
          ],
        };
      }
      if (source === 'gsuite') {
        const notSyncedGsuiteSources = GSuiteSources.filter(
          (_source) => !syncedSources.includes(_source),
        );
        if (notSyncedGsuiteSources.length === GSuiteSources.length) {
          return {
            ..._organisationSources,
            sourcesToBeConnected: ['gsuite', ..._organisationSources.sourcesToBeConnected],
          };
        }
        return {
          connectedSources: [
            ..._organisationSources.connectedSources,
            ...GSuiteSources.filter((_source) => syncedSources.includes(_source)),
          ],
          sourcesToBeConnected: [
            ..._organisationSources.sourcesToBeConnected,
            ...notSyncedGsuiteSources,
          ],
        };
      }
      return _organisationSources;
    },
    {
      connectedSources: [],
      sourcesToBeConnected: [],
    } as UserSources,
  );
  return {
    connectedSources: Array.from(new Set(organisationSources.connectedSources)),
    sourcesToBeConnected: Array.from(new Set(organisationSources.sourcesToBeConnected)),
  };
};

interface UserSources {
  connectedSources: ExtendedSources[];
  sourcesToBeConnected: ExtendedSources[];
}
